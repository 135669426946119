import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";
import Sortable from "sortablejs";
import updateAssociations from "../services/bookingResourceSkusService/updateAssociations";
import removeAssociations from "../services/bookingResourceSkusService/removeAssociations";

export default class extends Controller {
  connect() {
    this.sortable = new Sortable(this.element, {
      group: "transfer-associations",
      sort: false,
      animation: 150,
      handle: "[data-sortable-handle]",
      direction: "vertical",
      onEnd: this.handleDrop.bind(this),
    });
  }

  disconnect() {
    this.sortable.destroy();
  }

  handleDrop(event) {
    const draggedItem = event.item;
    if (!draggedItem) return;

    const dropTarget = event.originalEvent.target.closest("[data-sku-handle]");

    if (!dropTarget) return;

    const draggedHandle = draggedItem.dataset.skuHandle;
    const targetHandle = dropTarget.dataset.skuHandle;

    if (
      !draggedHandle?.startsWith("transfer-other") ||
      !targetHandle?.startsWith("transfer-person")
    ) {
      this.constructor.showInvalidDropIndicator(dropTarget);
      return;
    }

    const dependentSkuId = draggedItem.dataset.bookingResourceSkuId;
    const parentSkuId = dropTarget.dataset.bookingResourceSkuId;

    this.constructor.addLoadingOverlay(dropTarget);

    updateAssociations({
      bookingResourceSkuId: parentSkuId,
      associatedBookingResourceSkuId: dependentSkuId,
      success: async () => {
        await this.constructor.updateUI();
        this.constructor.removeLoadingOverlay(dropTarget);
      },
      error: async () => {
        event.from.appendChild(event.item);
        await this.constructor.updateUI();
        this.constructor.removeLoadingOverlay(dropTarget);
      },
    });
  }

  static updateUI() {
    return Turbo.visit(window.location.href, {
      frame: "booking_resource_skus",
    });
  }

  static addLoadingOverlay(target) {
    const targetElement = target;
    targetElement.style.position = "relative";

    const template = document.getElementById("loading-overlay-template");
    const overlay = template.content.cloneNode(true).firstElementChild;
    targetElement.appendChild(overlay);
  }

  static removeLoadingOverlay(target) {
    const overlay = target.querySelector(".absolute");
    if (overlay) overlay.remove();
  }

  static showInvalidDropIndicator(element) {
    const template = document.getElementById("invalid-drop-indicator");
    const indicator = template.content.cloneNode(true);

    element.classList.add("relative");
    element.appendChild(indicator);

    setTimeout(() => {
      element.classList.remove("relative");
      element.removeChild(element.lastElementChild);
    }, 1000);
  }

  breakRelationship(event) {
    const associatedBookingResourceSkuId =
      event.currentTarget.dataset.bookingResourceSkuId;
    const targetElement = event.currentTarget.closest("[data-sku-handle]");

    this.constructor.addLoadingOverlay(targetElement);
    removeAssociations({
      associatedBookingResourceSkuId,
      success: async () => {
        await this.constructor.updateUI();
        this.constructor.removeLoadingOverlay(targetElement);
      },
      error: async () => {
        await this.constructor.updateUI();
        this.constructor.removeLoadingOverlay(targetElement);
      },
    });
  }
}
