import Rails from "@rails/ujs";

export default ({ associatedBookingResourceSkuId, success, error }) => {
  Rails.ajax({
    type: "DELETE",
    dataType: "json",
    url: `/api/booking_resource_skus/${associatedBookingResourceSkuId}/remove_associations`,
    beforeSend: (xhr) => {
      // Workaround: add options.data late to avoid Content-Type header to already being set in stone
      // https://github.com/rails/rails/blob/master/actionview/app/assets/javascripts/rails-ujs/utils/ajax.coffee#L53
      xhr.setRequestHeader("Content-Type", "application/json; charset=UTF-8");
      return true;
    },
    success,
    error,
  });
};
