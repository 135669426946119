import Rails from "@rails/ujs";

export default ({
  bookingResourceSkuId,
  associatedBookingResourceSkuId,
  success,
  error,
}) => {
  Rails.ajax({
    type: "POST",
    dataType: "json",
    url: `/api/booking_resource_skus/update_associations`,
    beforeSend: (xhr, options) => {
      // Workaround: add options.data late to avoid Content-Type header to already being set in stone
      // https://github.com/rails/rails/blob/master/actionview/app/assets/javascripts/rails-ujs/utils/ajax.coffee#L53
      xhr.setRequestHeader("Content-Type", "application/json; charset=UTF-8");
      // eslint-disable-next-line no-param-reassign
      options.data = JSON.stringify({
        booking_resource_sku_id: bookingResourceSkuId,
        associated_booking_resource_sku_id: associatedBookingResourceSkuId,
      });

      return true;
    },
    success,
    error,
  });
};
